import { ethers } from 'ethers'

export default () => {
  const wallet = ethers.Wallet.createRandom()

  return {
    eth_address: wallet.address,
    eth_private_key: wallet.privateKey,
    eth_seed_phrase: wallet.mnemonic.phrase
  }
}
