import MakeRequest from './MakeRequest'

export default {
  GetPaymentHistory () {
    return MakeRequest('get', 'v1/payments.json', {}).then((resp) => {
      return resp.body
    })
  },

  SetLiveMode () {
    return MakeRequest('post', 'v1/live.json', {}).then((resp) => {
      return resp.body
    })
  },

  SetTestMode () {
    return MakeRequest('delete', 'v1/live.json', {}).then((resp) => {
      return resp.body
    })
  },

  ProfileUpdate (payload) {
    return MakeRequest('post', 'v1/me.json', payload).then((resp) => {
      return resp.body
    })
  }
}
