
const routes = [
  {
    path: '/',
    component: () => import('layouts/PublicLayout.vue'),
    children: [
      {
        path: '',
        name: 'public.register',
        component: () => import('pages/public/Register.vue'),
        meta: { noAuth: true }
      },
      {
        path: 'sign-in',
        name: 'public.login',
        component: () => import('pages/public/Login.vue'),
        meta: { noAuth: true }
      },
      {
        path: 'sdk/demo',
        name: 'sdk.demo',
        component: () => import('pages/public/SDKDemo.vue'),
        meta: { noAuth: true, skipSessionCheck: true }
      },
      {
        path: 'forgot-password',
        name: 'public.forgot-password',
        component: () => import('pages/public/ForgotPassword.vue'),
        meta: { noAuth: true }
      },
      {
        path: 'reset-password',
        name: 'public.reset-password',
        component: () => import('pages/public/ResetPassword.vue'),
        meta: { noAuth: true },
        props: (route) => {
          return Object.assign({}, route.params, route.query)
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('layouts/DefaultLayout.vue'),
    children: [
      {
        path: 'assets/:id',
        name: 'asset',
        component: () => import('pages/Asset.vue'),
        props: (route) => {
          return Object.assign({}, route.params, route.query)
        }
      },
      {
        path: 'collections/:id',
        name: 'collection',
        component: () => import('pages/user/collections/PublicCollection.vue'),
        props: (route) => {
          return Object.assign({}, route.params, route.query)
        }
      },
      {
        path: 'collections',
        name: 'collections',
        component: () => import('pages/Collections.vue')
      },
      {
        path: 'offers',
        name: 'offers',
        component: () => import('pages/Offers.vue')
      },
      {
        path: 'shopify/collection-asset-templates',
        name: 'shopify.collection-asset-templates',
        component: () => import('pages/user/shopify/Listing.vue')
      },
      {
        path: 'shopify/settings',
        name: 'shopify.settings',
        component: () => import('pages/user/shopify/Settings.vue')
      }
    ]
  },
  {
    path: '/user',
    component: () => import('layouts/DefaultLayout.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'user.dashboard',
        component: () => import('pages/user/Dashboard.vue')
      },
      {
        path: 'express-mint',
        name: 'user.collections.express-mint',
        component: () => import('pages/user/collections/ExpressMint.vue')
      },
      {
        path: 'collections/create',
        name: 'user.collections.create',
        component: () => import('pages/user/collections/Create.vue')
      },
      {
        path: 'collections/:id',
        name: 'user.collection',
        component: () => import('pages/user/collections/Collection.vue'),
        props: (route) => {
          return Object.assign({}, route.params, route.query)
        }
      },
      {
        path: 'collections',
        name: 'user.collections',
        component: () => import('pages/user/collections/Listing.vue')
      },
      {
        path: 'offers/create',
        name: 'user.offers.create',
        component: () => import('pages/user/offers/Create.vue'),
        props: (route) => {
          return Object.assign({}, route.params, route.query)
        }
      },
      {
        path: 'offers/:id',
        name: 'user.offer',
        component: () => import('pages/user/offers/Offer.vue'),
        props: (route) => {
          return Object.assign({}, route.params, route.query)
        }
      },
      {
        path: 'offers',
        name: 'user.offers',
        component: () => import('pages/user/offers/Listing.vue')
      },
      {
        path: 'asset/:id',
        name: 'user.asset',
        component: () => import('pages/user/collections/Asset.vue'),
        props: (route) => {
          return Object.assign({}, route.params, route.query)
        }
      },
      {
        path: 'template/:id',
        name: 'user.collection-asset-template',
        component: () => import('pages/user/collections/CollectionAssetTemplate.vue'),
        props: (route) => {
          return Object.assign({}, route.params, route.query)
        }
      }
    ]
  },
  {
    path: '/account',
    component: () => import('layouts/DefaultLayout.vue'),
    children: [
      {
        path: 'profile',
        name: 'user.account.profile',
        component: () => import('pages/user/account/Profile.vue')
      },
      {
        path: 'developer',
        name: 'user.account.developer',
        component: () => import('pages/user/account/Developer.vue')
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
