<template>
  <router-view v-if="sessionChecked" />
</template>
<script>
import { useOneSignal } from '@onesignal/onesignal-vue3'
import { useQuasar } from 'quasar'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  toRefs
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'App',

  setup () {
    const stateReactive = reactive({
      sessionChecked: false
    })

    const store$ = useStore()
    const os$ = useOneSignal()
    const isLoggedIn = computed(() => store$.getters['session/hasToken'])
    const refreshSession = () => store$.dispatch('session/refreshSession')
    const unsubscribeAction = store$.subscribeAction({
      before: () => {},
      after: (action, state) => {
        if (action.type === 'session/refreshSession') {
          stateReactive.sessionChecked = true
        }
      }
    })

    onBeforeMount(async () => {
      try {
        const playerId = await os$.getUserId()
        store$.dispatch('session/updatePlayerId', playerId, { root: true })
      } catch (error) {
        console.log(error)
      }
    })

    const q$ = useQuasar()
    const route$ = useRoute()
    const router$ = useRouter()
    router$.isReady().then(() => {
      const skipSessionCheck = (route$.meta && route$.meta.skipSessionCheck) || false

      if (isLoggedIn.value && !skipSessionCheck) {
        handleRefreshSession()
      } else {
        stateReactive.sessionChecked = true
      }
    })

    const handleRefreshSession = () => {
      q$.loading.show({ message: 'Checking session. Please wait.' })

      refreshSession()
        .finally(() => {
          q$.loading.hide()
        })
    }

    onBeforeUnmount(() => {
      unsubscribeAction()
    })

    return {
      ...toRefs(stateReactive)
    }
  }
})
</script>

<style>
.table-row {
  padding-top: 0.5em;
}

.table-row-label {
  width: 10em;
  font-weight: bold;
}

.table-row-value {
  padding-left: 1em;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
.required-field .q-field__label:before {
  content: '*';
  color: red;
}

/* Helper Icon Color White */
.h-i-c-w .q-icon {
  color: white !important;
}

.q-loading__backdrop {
  opacity: 0.8;
}

/* Helper Text Decoration None */
.h-t-decoration-n {
  text-decoration: none !important;
}
</style>

<style lang="scss">
.required-field .q-field__inner {
  background-color: $indigo-2 !important;
}

.icon-primary .q-btn__content .q-icon {
  color: $primary !important;
}
.icon-negative .q-btn__content .q-icon {
  color: $negative !important;
}
</style>
