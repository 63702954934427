import VuexPersistence from 'vuex-persist'

// "async" is optional
export default async ({ store }) => {
  new VuexPersistence({
    storage: window.localStorage,
    key: '__session',
    modules: ['session']
  }).plugin(store)
}
