/**
 * XXX On updating this initial state, please make corresponding changes
 * to the session object declared in _manage.html
 */
export default {
  user: null,
  token: null,
  redirectOnLogin: null,
  playerId: null,
  tourGuide: {
    dashboard: false,
    ownedCollections: false,
    publicCollections: false,
    crypto: {
      matic: {
        initial: false,
        createTestnet: false,
        createMainnet: false
      }
    }
  }
}
