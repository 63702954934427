export function updateUser (state, { user, ...payload }) {
  state.user = user

  if (typeof payload.token !== 'undefined') {
    state.token = payload.token
  }
}

export function updateToken (state, token) {
  state.token = token
}

export function updateDashboardTourGuide (state) {
  state.tourGuide.dashboard = true
}

export function updateCollectionsTourGuide (state) {
  state.tourGuide.ownedCollections = true
}

export function updatePublicCollectionsTourGuide (state) {
  state.tourGuide.publicCollections = true
}

export function updateTourGuideCryptoMaticInitial (state) {
  state.tourGuide.crypto.matic.initial = true
}

export function updateTourGuideCryptoMaticCreateMainnet (state) {
  state.tourGuide.crypto.matic.createMainnet = true
}

export function updateTourGuideCryptoMaticCreateTestnet (state) {
  state.tourGuide.crypto.matic.createTestnet = true
}

export function resetTourGuide (state) {
  state.tourGuide.publicCollections = false
  state.tourGuide.dashboard = false
  state.tourGuide.ownedCollections = false
  state.tourGuide.crypto.matic.createTestnet = false
  state.tourGuide.crypto.matic.createMainnet = false
  state.tourGuide.crypto.matic.initial = false
}

export function skipTourGuide (state) {
  state.tourGuide.publicCollections = true
  state.tourGuide.dashboard = true
  state.tourGuide.ownedCollections = true
  state.tourGuide.crypto.matic.createTestnet = true
  state.tourGuide.crypto.matic.createMainnet = true
  state.tourGuide.crypto.matic.initial = true
}

export function logoutUser (state) {
  state.token = null
  state.user = null
}

export function updatePlayerId (state, playerId) {
  state.playerId = playerId
}

export function setRedirectOnLogin (state, route) {
  state.redirectOnLogin = Object.assign({}, route)
}

export function resetRedirectOnLogin (state) {
  state.redirectOnLogin = null
}

export function favoriteCollection (state, collectionId) {
  state.user.favorites = state.user.favorites ?? []
  const idx = (state.user.favorites).findIndex((val) => val === collectionId)
  if (idx < 0) {
    state.user.favorites.push(collectionId)
  }
}

export function unfavoriteCollection (state, collectionId) {
  state.user.favorites = state.user.favorites ?? []
  const idx = (state.user.favorites).findIndex((val) => val === collectionId)
  if (idx >= 0) {
    state.user.favorites.splice(idx, 1)
  }
}

export function watchCollection (state, collectionId) {
  state.user.watchlist = state.user.watchlist ?? []
  const idx = (state.user.watchlist).findIndex((val) => val === collectionId)
  if (idx < 0) {
    state.user.watchlist.push(collectionId)
  }
}

export function unwatchCollection (state, collectionId) {
  state.user.watchlist = state.user.watchlist ?? []
  const idx = (state.user.watchlist).findIndex((val) => val === collectionId)
  if (idx >= 0) {
    state.user.watchlist.splice(idx, 1)
  }
}
