import MakeRequest from './MakeRequest'

export default {
  Register (payload) {
    return MakeRequest('post', '/v1/register.json', payload, { needsNoAuth: true }).then((resp) => {
      return resp.body
    })
  },

  Login (payload) {
    return MakeRequest('post', '/v1/login.json', payload, { needsNoAuth: true }).then((resp) => {
      return resp.body
    })
  },

  Profile () {
    return MakeRequest('get', '/v1/me.json').then((resp) => {
      return resp.body
    })
  },

  Logout () {
    return MakeRequest('post', '/v1/logout.json').then((resp) => {
      return resp.body
    })
  },

  SyncWalletBalances () {
    return MakeRequest('get', '/v1/sync-wallet-balances.json').then((resp) => {
      return resp.body
    })
  },

  // Get details of NFT transfer-by-email
  GetEmailTransferInvite (payload) {
    const url = '/v1/email-transfer.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('get', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  ClaimNFT (payload) {
    const url = '/v1/claim-nft.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('post', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  // Add a Card to User's Stripe account
  AddCard (token, card = {}) {
    return MakeRequest('post', 'v1/card.json', { token, card }).then((resp) => {
      return resp.body
    })
  },

  DeleteCard (cardId) {
    return MakeRequest('delete', `v1/card/${cardId}.json`).then((resp) => {
      return resp.body
    })
  },

  BuyMatic (cardId, amount) {
    const payload = {
      card_id: cardId,
      amount
    }

    return MakeRequest('post', 'v1/buy/matic.json', payload).then((resp) => {
      return resp.body
    })
  },

  GetMaticExchangeRate () {
    return MakeRequest('get', 'v1/buy/matic.json', {}).then((resp) => {
      return resp.body
    })
  },

  // Get details of NFT transfer-batch-by-email
  GetEmailTransferInviteBatch (payload) {
    const url = '/v1/email-transfer-batch.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('get', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  ClaimNFTBatch (payload) {
    const url = '/v1/claim-nft-batch.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('post', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  // Get details of NFTemplate
  GetNFTemplate (payload) {
    const url = '/v1/template-transfer.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('get', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  // Get payment-intent for NFTemplate with pricing
  GetNFTemplatePaymentIntent (payload) {
    const url = '/v1/template-payment-intent.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('post', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  // Claim NFTemplate
  ClaimNFTemplate (payload) {
    const url = '/v1/claim-nft-template.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('post', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  PasswordResetEmailSend (payload) {
    return MakeRequest('post', '/v1/password-reset-email.json', payload, { needsNoAuth: true }).then((resp) => {
      return resp.body
    })
  },

  PasswordReset (payload) {
    return MakeRequest('post', '/v1/password-reset.json', payload, { needsNoAuth: true }).then((resp) => {
      return resp.body
    })
  }
}
