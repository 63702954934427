/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {QSpinnerClock,Loading,Meta,Notify,Dark} from 'quasar'



const userOptions = { config: {"loading":{"backgroundColor":"primary","boxClass":"rounded-borders text-h6","message":"Loading. Please wait...","messageColor":"white","spinner":"QSpinnerClock","spinnerColor":"white"}},components: {QSpinnerClock},plugins: {Loading,Meta,Notify,Dark} }
userOptions.config.loading.spinner = QSpinnerClock
export default userOptions

