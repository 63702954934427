/*
import { boot } from 'quasar/wrappers'
import axios from 'axios'

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({ baseURL: 'https://api.example.com' })

export default boot(({ app }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
})

export { api }
 */
import axios from 'axios'

const SessionCheckMethod = 'get'
const SessionCheckUrl = '/v1/me.json'

export default async ({ app, store, router }) => {
  if (process.env.PROD) {
    axios.defaults.baseURL = JSON.parse(process.env.API_URL)
  }

  axios.interceptors.request.use((config) => {
    if (!config.url.match(/\.json$/)) {
      return config
    }

    const needsNoAuth = typeof config.headers['X-PASSTHRU'] !== 'undefined'
      ? config.headers['X-PASSTHRU']
      : false

    const authToken = store.state.session.token
    const oneSignalPlayerid = store.state.session.playerId

    if (needsNoAuth === false && authToken) {
      config.headers.Authorization = 'Bearer ' + authToken
    }

    if (oneSignalPlayerid) {
      config.headers['Device-Info'] = 'ospid=' + oneSignalPlayerid
    }

    delete config.headers['X-PASSTHRU']
    return config
  })

  axios.interceptors.response.use((response) => {
    const headers = response.headers

    if (typeof response.status !== 'undefined' && response.status === 401) {
      store.dispatch('session/logout', null, { root: true })
      return response
    }

    // token refresh - update client session
    if (headers.authorization !== undefined && store.getters['session/isLoggedIn']) {
      store.dispatch('session/updateToken', headers.authorization, { root: true })
    }

    return response
  }, (error) => {
    // global error handling
    // Considering 401 responses as unauthenticated
    // AND
    // Considering any errors on session-check/session-refresh request as unauthenticated
    const isUnauthenticated = error.response?.status === 401 ||
      (error.config.url === SessionCheckUrl && error.config.method.toLowerCase() === SessionCheckMethod)

    if (isUnauthenticated) {
      store.dispatch('session/logout', null, { root: true })

      const currentRoute = {
        name: router.currentRoute.name,
        path: router.currentRoute.path,
        params: router.currentRoute.params
      }
      store.dispatch('session/setRedirectOnLogin', currentRoute, { root: true })

      router.push({ name: 'public.login' })
    }

    return Promise.reject(error)
  })

  // app.prototype.$axios = axios
  app.config.globalProperties.$axios = axios
}
