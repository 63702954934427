import MakeRequest from './MakeRequest'

export default {
  Create (payload) {
    return MakeRequest('post', '/v1/offer.json', payload).then((resp) => {
      return resp.body
    })
  },

  Get (id) {
    return MakeRequest('get', `/v1/offer/${id}.json`).then((resp) => {
      return resp.body
    })
  },

  Offers (payload) {
    return MakeRequest('get', '/v1/offers.json', payload).then((resp) => {
      return resp.body
    })
  },

  UpdateCollections (id, payload) {
    return MakeRequest('post', `/v1/offer/${id}/collections.json`, payload).then((resp) => {
      return resp.body
    })
  },

  UserOffers (payload) {
    return MakeRequest('get', '/v1/user/offers.json', payload).then((resp) => {
      return resp.body
    })
  },

  Update (id, payload) {
    return MakeRequest('post', `/v1/offer/${id}.json`, payload).then((resp) => {
      return resp.body
    })
  },

  TargetCollection (offerId, collectionId) {
    return MakeRequest('post', `/v1/offer/target/${offerId}.json`, { collectionId: collectionId }).then((resp) => {
      return resp.body
    })
  },

  UntargetCollection (offerId, collectionId) {
    return MakeRequest('post', `/v1/offer/untarget/${offerId}.json`, { collectionId: collectionId }).then((resp) => {
      return resp.body
    })
  }
}
