import MakeRequest from './MakeRequest'

export default {
  Publish (id, payload = {}) {
    return MakeRequest('post', `/v1/shopify/publish/${id}.json`, payload).then((resp) => {
      return resp.body
    })
  },

  UnPublish (id) {
    return MakeRequest('post', `/v1/shopify/unpublish/${id}.json`, {}).then((resp) => {
      return resp.body
    })
  },

  Unlink () {
    return MakeRequest('get', '/v1/shopify/unlink.json').then((resp) => {
      return resp.body
    })
  },

  CollectionAssetTemplates (payload) {
    return MakeRequest('get', '/v1/shopify/collection-asset-templates.json', payload).then((resp) => {
      return resp.body
    })
  },
  WebhookKey (payload = {}) {
    return MakeRequest('post', '/v1/shopify/save-webhook-key.json', payload).then((resp) => {
      return resp.body
    })
  },
  UpdateWebhookKey (payload = {}) {
    return MakeRequest('post', '/v1/shopify/update-webhook-key.json', payload).then((resp) => {
      return resp.body
    })
  },
  UnsetRedirectFromShopify (payload = {}) {
    return MakeRequest('get', '/v1/shopify/redirect-from-shopify.json', payload).then((resp) => {
      return resp.body
    })
  },
  RewardNft (id, payload = {}) {
    return MakeRequest('post', `/v1/shopify/shopify-reward-nft/${id}.json`, payload).then((resp) => {
      return resp.body
    })
  }
}
