export function isLoggedIn (state) {
  if (state.token && state.user && state.user.email) {
    return true
  }

  return false
}

export function hasRedirectOnLogin (state) {
  if (state.redirectOnLogin !== null) {
    return true
  }

  return false
}

export function hasToken (state) {
  return state.token !== null
}
