import {
  AuthApi,
  WalletApi
} from '@/api'
import walletGetRandom from '@/libs/wallet-get-random'

export function login (context, payload) {
  if (typeof payload.user !== 'undefined') {
    context.commit('updateUser', payload)
  }
}

export function logout (context) {
  context.commit('logoutUser')
}

export function updateToken (context, token) {
  context.commit('updateToken', token)
}

export function updatePlayerId (context, playerId) {
  context.commit('updatePlayerId', playerId)
}

export function updateDashboardTourGuide (context) {
  context.commit('updateDashboardTourGuide')
}

export function updateCollectionsTourGuide (context) {
  context.commit('updateCollectionsTourGuide')
}

export function updatePublicCollectionsTourGuide (context) {
  context.commit('updatePublicCollectionsTourGuide')
}

export function updateTourGuideCryptoMaticInitial (context) {
  context.commit('updateTourGuideCryptoMaticInitial')
}

export function updateTourGuideCryptoMaticCreateMainnet (context) {
  context.commit('updateTourGuideCryptoMaticCreateMainnet')
}

export function updateTourGuideCryptoMaticCreateTestnet (context) {
  context.commit('updateTourGuideCryptoMaticCreateTestnet')
}

export function resetTourGuide (context) {
  context.commit('resetTourGuide')
}

export function skipTourGuide (context) {
  context.commit('skipTourGuide')
}

export function setRedirectOnLogin (context, route) {
  if (route.name !== 'login') {
    context.commit('setRedirectOnLogin', route)
  }
}

export function resetRedirectOnLogin (context) {
  context.commit('resetRedirectOnLogin')
}

export async function refreshSession (context) {
  return AuthApi.Profile().then((body) => {
    context.commit('updateUser', body.data)
  }).catch((error) => {
    console.error('Refresh session error:', error)
    context.commit('logoutUser')
  })
}

export async function addWallet (context) {
  return WalletApi.AddWallet(walletGetRandom()).then((body) => {
    context.commit('updateUser', body.data)
  }).catch((error) => {
    console.error('Error adding wallet:', error)
  })
}

export function favoriteCollection (context, collectionId) {
  context.commit('favoriteCollection', collectionId)
}

export function unfavoriteCollection (context, collectionId) {
  context.commit('unfavoriteCollection', collectionId)
}

export function watchCollection (context, collectionId) {
  context.commit('watchCollection', collectionId)
}

export function unwatchCollection (context, collectionId) {
  context.commit('unwatchCollection', collectionId)
}
