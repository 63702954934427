import MakeRequest from './MakeRequest'

export default {
  Create (payload) {
    return MakeRequest('post', '/v1/collection.json', payload).then((resp) => {
      return resp.body
    })
  },

  Collections (payload) {
    return MakeRequest('get', '/v1/collections.json', payload).then((resp) => {
      return resp.body
    })
  },

  Get (id) {
    return MakeRequest('get', `/v1/collection/${id}.json`).then((resp) => {
      return resp.body
    })
  },

  UpdateOffers (id, payload) {
    return MakeRequest('post', `/v1/collection/${id}/offers.json`, payload).then((resp) => {
      return resp.body
    })
  },

  UserCollections (payload) {
    return MakeRequest('get', '/v1/user/collections.json', payload).then((resp) => {
      return resp.body
    })
  },

  PublicCollection (id) {
    return MakeRequest('get', `/v1/collections/${id}.json`).then((resp) => {
      return resp.body
    })
  },

  GetAssets (id, payload) {
    return MakeRequest('get', `/v1/collections/${id}/assets.json`, payload).then((resp) => {
      return resp.body
    })
  },

  CreateNFT (id, payload) {
    return MakeRequest('post', `/v1/collections/${id}/nft.json`, payload).then((resp) => {
      return resp.body
    })
  },

  TransferNFT (id, payload) {
    return MakeRequest('post', `/v1/asset/${id}/transfer.json`, payload).then((resp) => {
      return resp.body
    })
  },

  NFT (id) {
    return MakeRequest('get', `v1/asset/${id}.json`).then((resp) => {
      return resp.body
    })
  },

  OfferTarget (collectionId, offerId) {
    return MakeRequest('post', `/v1/collection/target/${collectionId}.json`, { offerId: offerId }).then((resp) => {
      return resp.body
    })
  },

  OfferUntarget (collectionId, offerId) {
    return MakeRequest('post', `/v1/collection/untarget/${collectionId}.json`, { offerId: offerId }).then((resp) => {
      return resp.body
    })
  },

  PublicNFT (assetId) {
    return MakeRequest('get', `v1/public/assets/${assetId}.json`).then((resp) => {
      return resp.body
    })
  },

  /**
   * Cancel any pending/unclaimed email invites for a given asset.
   *
   * @param {ObjectID} assetId Asset attached to the invitation
   */
  CancelTransfer (assetId, payload = {}) {
    return MakeRequest('post', `v1/asset/cancel-transfers/${assetId}.json`, payload).then((resp) => {
      return resp.body
    })
  },

  /**
   * Add given Collection to current user's favourites.
   *
   * @param {ObjectID} collectionId
   * @param {object} payload
   */
  AddFavorite (collectionId, payload = {}) {
    return MakeRequest('post', `v1/favorite/${collectionId}.json`, payload).then((resp) => {
      return resp.body
    })
  },

  /**
   * Remove given Collection from current user's favourites.
   *
   * @param {ObjectID} collectionId
   */
  RemoveFavorite (collectionId) {
    return MakeRequest('delete', `v1/favorite/${collectionId}.json`, {}).then((resp) => {
      return resp.body
    })
  },

  /**
   * Add given Collection to current user's watchlist.
   *
   * @param {ObjectID} collectionId
   * @param {object} payload
   */
  AddToWatchlist (collectionId, payload = {}) {
    return MakeRequest('post', `v1/watchlist/${collectionId}.json`, payload).then((resp) => {
      return resp.body
    })
  },

  /**
   * Remove given Collection from current user's watchlist.
   *
   * @param {ObjectID} collectionId
   */
  RemoveFromWatchlist (collectionId) {
    return MakeRequest('delete', `v1/watchlist/${collectionId}.json`, {}).then((resp) => {
      return resp.body
    })
  },

  CloneNFT (assetId, payload) {
    return MakeRequest('post', `/v1/asset/${assetId}/clone.json`, payload).then((resp) => {
      return resp.body
    })
  },

  ExpressMint (payload) {
    return MakeRequest('post', '/v1/express-mint.json', payload).then((resp) => {
      return resp.body
    })
  },

  TransferNFTBatch (collectionId, payload) {
    return MakeRequest('post', `/v1/collection/${collectionId}/transfer.json`, payload).then((resp) => {
      return resp.body
    })
  },

  EstimateFee (payload) {
    return MakeRequest('get', '/v1/estimate-fee.json', payload).then((resp) => {
      return resp.body
    })
  },

  NFTTrash (collectionId, assedId) {
    return MakeRequest('delete', `/v1/asset/${collectionId}/${assedId}.json`, {}).then((resp) => {
      return resp.body
    })
  },

  Trash (collectionId) {
    return MakeRequest('delete', `/v1/collection/${collectionId}.json`, {}).then((resp) => {
      return resp.body
    })
  },

  CollectionAssetTemplateCreate (collectionId, payload) {
    return MakeRequest('post', `/v1/asset-template/${collectionId}.json`, payload).then((resp) => {
      return resp.body
    })
  },

  CollectionAssetTemplateUpdate (id, payload) {
    return MakeRequest('post', `/v1/asset-template/${id}/update.json`, payload).then((resp) => {
      return resp.body
    })
  },

  // Gets collection-asset-template belonging to authenticated User
  CollectionAssetTemplateGet (id) {
    return MakeRequest('get', `/v1/asset-template/${id}.json`).then((resp) => {
      return resp.body
    })
  },

  // Gets paginated assets for collection-asset-template belonging to authenticated User
  CollectionAssetTemplateCollectionAssets (id, payload) {
    return MakeRequest('get', `/v1/asset-template/${id}/assets.json`, payload).then((resp) => {
      return resp.body
    })
  },

  // Mints collection-asset for collection-asset-template belonging to authenticated User
  CollectionAssetTemplateMint (id, payload) {
    return MakeRequest('post', `/v1/asset-template/${id}/mint.json`, payload).then((resp) => {
      return resp.body
    })
  },

  // Gets paginated assets for collection-asset-template belonging to authenticated User
  CollectionAssetTemplateCollectionAssetsCSVExport (id, payload) {
    return MakeRequest('get', `/v1/asset-template/${id}/csv/assets.json`, payload).then((resp) => {
      return resp.body
    })
  }
}
