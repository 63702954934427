import { boot } from 'quasar/wrappers'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'

export default boot(({ app }) => {
  const appId = process.env.ONESIGNAL_APP_ID.replace(/"/g, '')
  // Set OneSignal instance on app
  app.use(OneSignalVuePlugin, {
    appId
    // Enefits production
    // appId: 'ce255ea5-1afc-412a-8b0c-259fb592e327'
  })
})
