import axios from 'axios'

export default function MakeRequest (type, endpoint, data, options) {
  let headers = {}
  if (options && typeof options.headers !== 'undefined') {
    headers = options.headers
  }
  const config = {}

  if (options && options.needsNoAuth) {
    headers['X-PASSTHRU'] = true
  }

  let params = {}
  if (type.toLowerCase() === 'get') {
    params.params = data
  } else {
    params = data
  }

  return axios[type](endpoint, params, { headers, config }).then((response) => {
    delete response.config
    delete response.request

    response.body = response.data
    delete response.data

    return response
  })
}
